.meta-lesson-panel {
  width: 300px;
  height: auto;
  background: linear-gradient(
    180deg,
    #3a1587 74.89%,
    #490dc8 99.99%,
    #3703a4 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);

  border-radius: 20px;
  position: fixed;
  z-index: 2;
  padding: 12px 14px 16px 12px;

  flex-direction: column;

  .meta-lesson-panel_decoration {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: calc(100% - 70px);
    background-size: 100% auto;
    background-repeat: repeat-y;
    z-index: 0;
    overflow: hidden;
  }

  .meta-lesson-panel__blocked {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .meta-lesson-panel__blocked-icon {
    svg path {
      fill: var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__blocked-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-align: center;
    margin: 14px 0 12px 0;
  }

  .meta-lesson-panel__blocked-info {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-primary-color);
    text-align: center;

    .meta-lesson-panel__previous-name {
      display: block;
      font-size: 115%;
      padding-top: 0.5em;
    }
  }

  .meta-lesson-panel__previous-image {
    padding: 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 2px solid var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__previous-image {
    padding: 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__lesson-number {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--ui-primary-color);
    margin: 16px 0;
    text-align: center;
  }

  .meta-lesson-panel__lesson-pieces {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 12px 30px 12px;
    min-width: 200px;

    .meta-lesson-panel__lesson-pieces_gear {
      z-index: 2;

      svg {
        width: 44px;
        height: 44px;
      }
    }

    .meta-lesson-panel__lesson-pieces_bar {
      position: relative; /* Para moverlo tras el iconoy el número de piezas */
      left: -24px; /* Para moverlo tras el iconoy el número de piezas */
      margin-right: -34px; /* Para moverlo tras el iconoy el número de piezas */

      flex-grow: 1;
      height: 29px;
      border-radius: 22px;
      background: #3703a4;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

      .meta-lesson-panel__lesson-pieces_bar_foregrond {
        height: 100%;
        border-radius: 18px;
        background: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15) inset,
          0px 5px 4px 0px rgba(255, 255, 255, 0.25) inset;
      }
    }

    .meta-lesson-panel__lesson-pieces_number {
      display: inline-flex;
      padding: 16px 8px 17px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #fff;
      color: #000;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 0px;
      z-index: 2;

      div:first-of-type {
        font-size: 14px;
        font-weight: 700;
        padding-right: 3px;
      }
      div:last-of-type {
        padding-right: 1px;
      }
    }
  }

  .meta-lesson-panel__button {
    position: relative;
    z-index: 1000;
    display: flex;
    width: fit-content;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--Grays-white, #fff);
    color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    margin: 0 auto;
  }

  @media screen and (max-width: 520px) {
    width: 100vw;
    margin: 0;
    z-index: 100;
    border-radius: 20px 20px 0 0;
  }

  @media screen and (max-width: 1279px) {
    width: 360px;
    height: 334px;
    flex-shrink: 0;
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    position: fixed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
    animation: drawer-slideInBottom 0.5s ease-in-out;
    z-index: 100;
  }
}

.meta-lesson-panel.phaser-dom[data-fixed='true'] {
  top: auto !important;
  left: auto !important;
  position: relative !important;
  transform: none !important;
  visibility: visible !important;
}
