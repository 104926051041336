@import '../../../theme/index.scss';

.information-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
}

.information-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 405px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  max-width: 95%;

  background: $darkPurple;
  background-image: radial-gradient(
      50% 50% at 50% 0%,
      rgba(252, 90, 255, 0.5) 0%,
      rgba(198, 106, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 100%,
      rgba(252, 90, 255, 0.5) 0%,
      rgba(198, 106, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(252, 90, 255, 0.35) 0%,
      rgba(198, 106, 255, 0) 100%
    );
  background-position: top, bottom, center;
  background-repeat: no-repeat;
  background-size: 100% 50%, 100% 50%, 50% 100%;

  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
    0px 2px 0px 0px #9940c3 inset;
  border-radius: 8px;

  .information-popup_close_wrapper {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    background-color: #5a34e1;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    .information-popup_close_image {
      path {
        fill: $white;
      }
    }
  }

  .information-popup_message-wrapper-out {
    position: relative;
    width: 100%;
    margin-top: 125px;

    .information-popup_message-wrapper-in {
      position: relative;
      height: 88px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-wrap: break-word;
      padding: 0 32px;
      padding-top: 12px;
      padding-bottom: 12px;
      background-image: url('../../../assets/img/metaberry_ui/information-frame.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: -22px;

      .information-popup_message-text {
        z-index: 1;
        padding: 20px 10px;
        font-family: Poppins, sans-serif;
        color: $white;
        text-align: center;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
      }
    }
  }

  .information-popup_button {
    display: flex;
    gap: 10px;
    width: calc(100% - 64px);
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    margin-top: 32px;
    border-radius: 36px;
    background: $white;
    color: #5a34e1;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    margin-bottom: 32px;
  }

  .information-level-group {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -30px;

    .information-level-group__background {
      position: absolute;
      z-index: 2;
    }

    .information-level-group__shield {
      position: absolute;
      width: 38%;
      z-index: 4;
    }

    .information-level-group__level {
      position: absolute;
      width: 38%;
      height: 100%;
      z-index: 5;
      top: -46px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        color: $white;
        text-align: center;
        text-shadow: 3.566px 3.566px 0px rgba(0, 0, 0, 0.2);
        font-family: Quicksand, sans-serif;
        font-size: 50px;
        font-weight: 700;
        height: 100%;
        position: relative;
      }
    }

    .information-level-group__batteryIcon {
      position: absolute;
      z-index: 3;
    }

    .information-level-group__shadow {
      position: absolute;
      top: 95px;
      width: 34%;
      height: 19px;
      flex-shrink: 0;
    }
  }

  .information-coins-group {
    position: relative;
    width: 80%;
    left: 41%;
    transform: translateX(-50%);
    margin-bottom: -25%;

    .information-coins-group__background {
      position: relative;
      width: 100%;
      left: 50%;
      top: 0;
      transform: translate(-50%, -40%);
    }

    .information-coins-group__coins-image {
      position: absolute;
      width: 88%;
      left: 50%;
      top: -26%;
      transform: translateX(-50%);
    }

    .information-coins-group__ribbon-image {
      position: absolute;
      width: 39%;
      left: 50%;
      top: 39%;
      transform: translateX(-50%);
    }

    .information-coins-group__coins-number {
      position: absolute;
      width: 39%;
      left: 50%;
      top: 40.6%;
      transform: translateX(-50%);

      color: $white;
      text-align: center;
      text-shadow: 0px 1.8px 0px rgba(0, 0, 0, 0.25);
      font-family: Poppins, sans-serif;
      font-size: 32px;
      font-weight: 600;
    }
  }

  &.information-popup__level-up {
    height: 269px;
    .information-level-group {
      top: 40px;
    }
    .information-popup_message-wrapper-out {
      top: 20px;
    }
  }

  .practice-complete-view_rewards-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 400px) {
    .information-popup_message-wrapper-out {
      padding: 0;

      .information-popup_message-wrapper-in {
        .information-popup_message-text,
        .information-popup_message-text_rewards {
          font-size: 20px;
          margin-right: -18px;
        }
      }
    }

    .information-coins-group .information-coins-group__coins-number {
      font-size: 26px;
    }

    .practice-complete-view_rewards-container {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }
}

.app-practice-page {
  .information-popup {
    .information-popup_message-wrapper-out {
      margin-top: 24px;
    }
  }
  .information-popup__end-of-challenge {
    .information-popup_message-wrapper-out {
      margin-top: 90px;
    }
  }
}

@keyframes bounceInUpCentered {
  0% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
  60% {
    transform: translate(-50%, -60%);
    opacity: 1;
  }
  80% {
    transform: translate(-50%, -45%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 520px) {
  .information-popup {
    .information-popup__level-up {
      height: 269px;
      .information-popup_message-wrapper-out {
        top: 50px;
      }

      .information-level-group {
        top: 50px;
      }
      .information-level-group__shadow {
        display: block;
        top: 106px;
      }
    }
    .practice-complete-view_rewards-container {
      margin: 0;
    }
  }
}

.information-popup.information-popup__end-of-challenge {
  height: 341px;
  .information-level-group__shadow {
    display: none;
  }
  .information-popup_message-wrapper-out {
    .information-popup_message-wrapper-in {
      .boldM {
        font-size: 20px;
      }
      .boldL {
        font-size: 18px;
      }
    }
  }
}
