@import '../../../../../assets/styles/common';

.meta-options-menu-drawer__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 1000;
}

.blueberry-popup {
  z-index: 1001;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;

  border-radius: 24px;
  background: linear-gradient(
    180deg,
    $violet-dark-2 74.89%,
    $violet 99.99%,
    $violet-dark-1 100%
  );
  box-shadow: 0px 4px 60px 0px $shadow-black-1 inset,
    0px 4px 50px 0px $shadow-black-1;

  .blueberry-popup_close_image {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background-color: #9940c3;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 17px;
      height: 17px;
      fill: #ffffff;
    }
  }

  .blueberry-popup__close-button {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background-color: #9940c3;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: #ffffff;
    }
  }
  .blueberry-popup_decoration {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: 100%;
    background-size: 100% auto;
    background-repeat: repeat-y;
    z-index: 0;
  }

  .close-button {
    position: absolute;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background-color: #9940c3;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    right: -5px;
  }

  .blueberry-popup_title {
    position: relative;
    display: flex;
    min-height: 83px;
    justify-content: center;
    align-items: center;
    border-radius: 24px 24px 0 0;
    padding: 0 60px;
    color: $white-1;
    background-color: $violet-dark-2;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
    font-size: 23px;
    font-weight: 500;
    z-index: 1;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    border-radius: 0;

    .blueberry-popup_title {
      border-radius: 0;
    }
    .close-button {
      right: 10px;
      top: 10px;
    }
  }

  @media only screen and (max-width: 520px) {
    margin-top: 0;
    height: 100vh;
  }
}

@media only screen and (max-width: 768px) {
  .blueberry-popup {
    margin-top: 40px;
    border-radius: 0;

    .close-button {
      right: 10px;
      top: 10px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .blueberry-popup {
    margin-top: 0;
    height: 100%;

    .blueberry-popup_title {
      border-bottom: 2.476px solid
        var(--Blueberry-Blue-Blue-01-primary, #5a34e1);
      background: #2b026f;
      color: var(--Neutral-Base-White, #fff);
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      border-radius: 0;
    }

    .blueberry-popup_close_image {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 17px;
      right: 17px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .blueberry-popup_decoration {
      top: 70px;
    }
  }
}
