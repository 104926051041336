#__blueberry-modal-container-simple {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1002;
}

.modal__hidden {
  opacity: 0;
}

.modal-simple {
  .modal__shadow-layer,
  .modal__content-wrapper-init {
    position: absolute;
    width: 100%;
    height: 100vh;
  }

  .modal__content-wrapper-icon {
    position: absolute;
    top: -64px;
    left: 38%;
    z-index: 10;
  }

  .modal__content-wrapper-icon-error {
    position: absolute;
    top: -109px;
    left: 106px;
    z-index: 10;
  }

  .modal__shadow-layer {
    background: black;
  }

  .modal__content-wrapper-init {
    display: flex;
    align-items: center;
    justify-content: center;

    .modal__content-wrapper-init__title {
      @include poppins-font($size: 20px, $weight: 700);
      text-align: center;
      color: $gray-1-simple;
      word-wrap: break-word;
      margin-bottom: 5px;
    }

    .modal__content-wrapper-init__subtitle {
      @include poppins-font($size: 16px, $weight: 400);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      min-width: 387px;
      color: $white;
      font-family: Poppins, sans-serif;
      word-wrap: break-word;
      padding: 0px 0 10px 0;
      z-index: 1;
    }

    .modal__content-wrapper-init__button {
      @include poppins-font($size: 16px, $weight: 400);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 36px;
      border: 1px solid var(--Grays-white, $white);
      background: var(--Grays-white, $white);
      width: 192px;
      height: 48px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 12px;
      padding-bottom: 12px;
      word-wrap: break-word;
      margin-top: 16px;
      z-index: 1;

      color: var(--Blueberry-Primary-Medium-blue, $violet-light-1);
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      &:hover {
        cursor: pointer;
      }

      &:active,
      &:target {
        box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25) !important;
        position: relative;
        top: 4px;
      }
    }
  }

  .modal__content {
    width: 467px;
    height: 317px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    max-width: 500px;
    border-radius: 20px;
    background: #683fac;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    padding: 24px;
    gap: 6;

    .information-popup_message-wrapper-out {
      width: 100%;
      position: relative;
      max-width: none;

      .boldL {
        font-size: 20px;
        padding: 20px 0;
        color: var(--Gray-scale-white, #fff);
        text-align: center;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        font-family: Poppins, sans-serif;
        font-weight: 700;
        line-height: normal;
      }

      .information-popup_message-wrapper-in {
        padding-right: 60px;
        z-index: 1;
      }

      .information-popup_message-audio-button {
        position: absolute;
        right: 8%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .modal__content {
      position: relative;
      z-index: 1;
    }
    .modal__content-decoration {
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translate(-50%);
      width: 60%;
      height: calc(100% - 70px);
      background-size: 100% auto;
      background-repeat: repeat-y;
      z-index: 0;
      overflow: hidden;
    }
  }

  .modal__close-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    right: -10px;
    top: -10px;
    border-radius: 100%;
    background-color: red;
    color: $white;
    z-index: calc(#{$modal-depth} + 1);
    cursor: pointer;
  }

  .iconClose {
    width: 25px;
    height: 25px;
    path {
      fill: white;
    }
  }
}

.modal-teacher-practice-exit {
  .modal__content-wrapper-icon {
    top: -100px;
  }
  .modal__content {
    padding-top: 86px;
  }
}

.modal-screen-portrait-practice {
  display: none;

  .modal__content-wrapper-icon {
    top: -93px;
  }
  .modal__content {
    padding-top: 40px;
  }
}

@media screen and (orientation: portrait) {
  .modal-screen-portrait-practice {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    transform-origin: center center;
    z-index: 9999;
  }
}

.modal-practice-network-error {
  .modal__content-wrapper-icon {
    display: none;
  }
  .modal__content {
    padding-top: 32px;
  }
}

@media screen and (max-width: 520px) {
  .modal-simple .modal__content {
    width: 100vw;
    height: 300px;
    border-radius: 20px 20px 0 0;
    background: #683fac;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
  }

  .modal-simple
    .modal__content-wrapper-init
    .modal__content-wrapper-init__subtitle {
    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    min-width: 313px;
  }

  .modal-simple .modal__content-wrapper-icon img {
    width: 105px;
    height: 105px;
  }
  .modal-simple .modal__content-wrapper-icon {
    left: 35%;
    top: -52px;
  }

  .modal-simple
    .modal__content-wrapper-init
    .modal__content-wrapper-init__button {
    display: flex;
    width: 225px;
    height: 48px;
    padding: 0;
  }

  .modal-simple
    .modal__content
    .information-popup_message-wrapper-out
    .information-popup_message-wrapper-in {
    width: calc(100% - 5px);
    height: 80px;
  }

  .modal-simple .modal__content .information-popup_message-wrapper-out .boldL {
    width: 266px;
  }

  .modal-simple
    .modal__content
    .information-popup_message-wrapper-out
    .information-popup_message-audio-button {
    right: 6%;
  }

  .modal-simple {
    .drawer-box--bottom {
      position: fixed;
      bottom: 0;
      animation: none;

      &.closing {
        animation: none;
      }
    }
  }
}
