@import '../../../../../assets/styles/common';

.lessons-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;

  // Ajustes para la barra de desplazamiento
  &::-webkit-scrollbar {
    width: 9px;
    height: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $white-1;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @-moz-document url-prefix() {
    // Ajustes para la barra de desplazamiento firefox
    scrollbar-width: thin;
    scrollbar-color: $white-1 transparent;
  }
}
