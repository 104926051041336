.map-level-counter {
  height: 100%;
  width: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .map-level-counter-img {
    width: 36px;
    filter: drop-shadow(1px 2px 0px rgba(0, 0, 0, 0.25));
  }

  .map-level-counter-text {
    position: absolute;
    top: -1px;
    left: 0;
    width: 36px;
    height: 40px;

    color: var(--Gray-scale-white, #fff);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 520px) {
  .map-level-counter .map-level-counter-img {
    width: 20px;
    height: 22px;
    flex-shrink: 0;
  }
  .confirm-popup-options__content {
    .map-level-counter {
      position: absolute;
      left: 60px;
    }
    .map-level-counter .map-level-counter-img {
      width: 38px;
      height: 42px;
      flex-shrink: 0;
    }
  }
  .map-level-counter .map-level-counter-text {
    color: var(--Gray-scale-white, #fff);
    text-align: center;
    text-shadow: 0.737px 0.737px 0px rgba(0, 0, 0, 0.2);
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    top: -9px;
  }
  .card-details__name-container--disabled {
    .map-level-counter .map-level-counter-text {
      color: var(--Gray-scale-white, #fff);
      text-align: center;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      top: -1px;
    }
  }
}
