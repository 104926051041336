.activity-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 16px 8px 16px;
  width: 100%;
  z-index: 2;

  .activity-header__first-line {
    display: flex;
    align-items: center;
    column-gap: 26px;
    width: 100%;

    .activity-header__information {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 5px;

      .activity-header__bar-container {
        width: 100%;
        height: 12px;
        border-radius: 14px;
        background: #b8c3f0;
        margin-right: 16px;

        .activity-header__bar-content {
          width: 0;
          height: 12px;
          border-radius: 14px;
          background: linear-gradient(135deg, #72c4ff 14.75%, #4325fa 100%);
          transition: width 1s;
          position: relative;
          display: flex;

          svg {
            position: absolute;
            width: auto;
            height: 28px;
            right: -18px;
            top: 50%;
            transform: translateY(-50%);
          }

          @keyframes bar-coin-correct-animation {
            0% {
              transform: translateY(-50%) scale(1);
              opacity: 1;
            }
            88% {
              transform: translateY(-50%) scale(1);
              opacity: 1;
            }
            98% {
              transform: translateY(calc(-50% - 150px)) scale(2);
              opacity: 1;
            }
            100% {
              transform: translateY(calc(-50% - 150px)) scale(2);
              opacity: 0;
            }
          }

          .activity-header__bar-coin-correct-animation {
            opacity: 0;
            animation: bar-coin-correct-animation 1400ms linear backwards;
          }
        }
      }
    }

    .activity-header__issue-report {
      z-index: 14;

      .practice-button {
        padding: 12px !important;
      }
    }
  }

  .activity-header__difficult-activity-badget-wrapper {
    position: relative;
    width: 100%;
    z-index: 10;

    .activity-header__difficult-activity-badget {
      display: flex;
      width: fit-content;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 23px;
      background: #ffffff;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

      color: #5a34e1;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;

      margin-top: 28px;
      cursor: pointer;

      svg {
        margin-right: 9px;
      }
    }

    .map-tabs-tooltip__tooltip {
      background-color: #4d586c;
      color: #ffffff;
      transform: translate(0px, 50px);
    }
    .map-tabs-tooltip__tooltip:before {
      background-color: #4d586c;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 36px 36px 8px 36px;

    .activity-header__first-line {
      .activity-header__information {
        position: absolute;
        left: 15%;
        right: calc(15% + 12px);
      }

      .activity-header__issue-report {
        position: absolute;
        right: 36px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 36px 36px 8px 36px;
    .activity-header__first-line {
      .activity-header__information {
        position: absolute;
        left: 25%;
        right: calc(25% + 12px);
      }
    }

    .activity-header__difficult-activity-badget-wrapper {
      .activity-header__difficult-activity-badget {
        position: absolute;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .activity-header__issue-report {
      display: none;
    }
  }
}
