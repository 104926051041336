.grid-container {
  display: grid;
  grid-template-columns: 10% 74% 14%;
  gap: 10px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 21px;

  @media (max-width: 1250px) {
    grid-template-columns: 12% 64% 18%;
    margin-left: 47px;
    margin-right: 47px;
    gap: 3px;
  }
  @media (max-width: $breakpoint-laptop) {
    grid-template-columns: 12% 58% 23%;
  }
  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 12% 53% 26%;
  }
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

.item-content-timer {
  height: 40px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 52px;
  background: rgba(0, 0, 0, 0.72);
  color: #fff; /* Puedes cambiar esto a tu color de texto preferido */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 83px;
  @include inter-font($size: 16px, $weight: 700);
  @media (max-width: 1250px) {
    margin-left: 0px;
  }

  &.item-content-timer_alert {
    background-color: red;
    animation: pulseClock 1s infinite;
  }

  @keyframes pulseClock {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.item-content {
  height: 40px;
  padding: 12px 24px;
  border-radius: 52px;
  background: #5a34e1;
  color: #fff; /* Puedes cambiar esto a tu color de texto preferido */
  @include inter-font($size: 16px, $weight: 700);
  line-height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 33px;
}

.grid-item.progress-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  background: #ffffff;
  height: 40px;
  border-radius: 52px;
  padding: 3px;
  box-sizing: border-box;
  width: 80%; /* Reduced from 95% */
  position: relative;
  margin: auto;
}

.progress-bar-background,
.progress-bar-fill {
  left: 5%; /* Centering by pushing it from the left */
  right: 5%; /* Centering by pushing it from the right */
}

.progress-bar-background {
  position: absolute;
  height: 15px;
  width: 90%;
  background: #acacf7;
  border-radius: 52px;
  left: 5%; /* Este cambio es para mantener la barra centrada */
  transform: translateY(10px);
}

.progress-bar-fill {
  position: absolute;
  height: 15px;
  background: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
  border-radius: 52px;
  width: 90%;
  left: 5%; /* Este cambio es para mantener la barra centrada */
  transform: translateY(10px);
}

.imgPlanet {
  position: absolute;
  right: 0;
  transform: translateY(-10px) translateX(18px);
  @media (min-width: $breakpoint-desktop-large) {
    transform: translateY(-10px) translateX(5px);
  }
}

.imgAvatar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-13%);
  z-index: 10;
  width: 64px;
  height: 64px;
}
