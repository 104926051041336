@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .activity-view_content {
    &.scaffold-mobile-visible {
      overflow-y: hidden !important;

      .activity-view_content__with-audio-button,
      .activity-view_content__without-audio-button {
        display: flex;
        flex-grow: 1;
        margin-bottom: 0 !important;
      }
    }
  }

  .blueberry-scaffold-mobile-container {
    height: 100%;
    position: relative;

    &.scaffoldin-mobile-preview {
      .lemo-keyboard.lemo-active,
      .lemo-scaffolding-mobile-layout {
        position: absolute;
      }
    }

    .lemo-scaffolding-main-question {
      height: 100%;
      max-height: 100%;
      padding: 0;
      // old added
      display: flex;
      flex-direction: column;
      background-color: #ffffff;

      .lemo-question-wrapper {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        // old added
        flex-grow: 1;

        > div:first-child {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow-y: auto;
          height: 1px;

          .lemo-question {
            z-index: 'reverse-layer';
          }
        }
      }
    }

    .lemo-layout-container {
      padding: 16px 0 0 0;

      .lemo-question-container {
        overflow-y: auto;
      }
    }

    .feedback-box {
      width: 100%;
      margin: 32px 0 16px 0 !important;
    }

    // Ajustes para botones ---------------------------------------
    .main-question-controls,
    .main-question-controls .lemo-button-full-width-wrapper,
    .scaffolding-mobile-controls .lemo-button-full-width-wrapper,
    .scaffolding-mobile-controls .lemo-button-columns-wrapper,
    .scaffolding-mobile-controls .lemo-finish-scaffolding {
      width: 100%;
      display: flex;
      place-content: space-between;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;
      padding: 16px 0 24px 0;
    }

    .main-question-controls .lemo-button-full-width-wrapper {
      width: fit-content;
      padding: 0;
      place-content: flex-end;
      margin: 0 0 0 auto;

      .lemo-restart-wrapper {
        display: flex;
        flex-grow: 1;
      }
    }

    .scaffolding-mobile-controls .lemo-button-full-width-wrapper {
      place-content: flex-end;
    }

    .scaffolding-mobile-controls .lemo-finish-scaffolding {
      place-content: flex-end;
      flex-flow: wrap-reverse;

      .lemo-show-answer-wrapper {
        display: flex;
        flex-grow: 1;
        // place-content: flex-end;
      }

      .lemo-restart-wrapper {
        display: flex;
        flex-grow: 1;
        place-content: flex-end;
      }
    }

    .check-button,
    .help-button,
    .solution-button,
    .next-button,
    .restart-button {
      flex-grow: unset;
    }

    .feedback-indicator {
      position: absolute;
      left: 0;
      bottom: 18px;
      z-index: 200;
    }

    &.blueberry-scaffold-mobile-width_tablet-size {
      .main-question-controls,
      .main-question-controls .lemo-button-full-width-wrapper,
      .scaffolding-mobile-controls .lemo-button-full-width-wrapper,
      .scaffolding-mobile-controls .lemo-button-columns-wrapper,
      .scaffolding-mobile-controls .lemo-finish-scaffolding {
        padding: 16px 0 16px 0;
      }

      .main-question-controls .lemo-button-full-width-wrapper {
        padding: 0;
      }

      .feedback-indicator {
        bottom: 10px;
      }
    }

    &.blueberry-scaffold-mobile-width_phone-size {
      .main-question-controls,
      .main-question-controls .lemo-button-full-width-wrapper,
      .scaffolding-mobile-controls .lemo-button-full-width-wrapper,
      .scaffolding-mobile-controls .lemo-button-columns-wrapper,
      .scaffolding-mobile-controls .lemo-finish-scaffolding {
        padding: 16px 0 8px 0;
      }

      .main-question-controls .lemo-button-full-width-wrapper {
        padding: 0;
      }

      .check-button,
      .help-button,
      .solution-button,
      .next-button,
      .restart-button {
        flex-grow: 1;
      }

      .lemo-button-full-width-wrapper {
        flex-grow: 1;
      }
    }
    // Fin ajustes para botones -----------------------------------

    // Ajuste estilo botones navegación
    .lemo-scaffold-navigation-counter,
    .lemo-scaffolding-navigation-button {
      @include activity-primary-font;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .lemo-scaffold-navigation-counter {
      font-weight: 500;
      font-style: normal;
    }

    .lemo-scaffolding-navigation-button {
      display: flex;
      max-height: 48px;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      padding: 12px 32px;
      width: fit-content !important;
      min-width: fit-content;
      height: 48px;          
      cursor: pointer;
      overflow: hidden;      
      gap: 8px;

      color: map-get($activity-colors, primary-medium);
      background-color: map-get($activity-colors, primary-light-2);
      border: none;

      path {
        fill: map-get($activity-colors, primary-medium);
      }

      &:active {
        background-color: map-get($activity-colors, primary-light-1);
      }

      &.lemo-scaffolding-navigation-prev-button,
      &.lemo-scaffolding-navigation-next-button {
        padding: 12px 24px;

        &[disabled] {
          background-color: map-get($activity-colors, disabled-back);
          color: map-get($activity-colors, disabled-front);

          path {
            fill: map-get($activity-colors, disabled-front);
          }
        }
      }
    }
  }
}
