.game-menu-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 100;
}

.search-seed-option_container {
  position: absolute;
  z-index: 101;
  top: 64px;
  right: 55px;

  border-radius: 12px;
  background: #5a34e1;
  box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;
  padding: 15px 13px;

  .search-seed-option_content {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: #3703a4;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 24px;
    width: 273px;
    height: auto;
    padding: 14px 12px;

    .search-seed-option_input-search {
      background-color: #fff;
      border-radius: 8px;
      width: 225px;
    }

    .search-seed-option_search-button {
      cursor: pointer;
      margin-left: 1px;
    }

    .search-seed-option_magnify-icon {
      width: 30px;
      margin-right: -8px;
    }
  }

  .search-seed-option_error-message {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: space-between;
    padding-top: 10px;

    &:last-child {
      margin-bottom: 0;
    }
    .seed-error-message {
      font-size: 12px;
      color: #fff;
      font-family: sans-serif;
    }
  }
}

.activity-popup-container {
  position: fixed;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: auto;
  max-height: 90%;
  border-radius: 8px;
  padding: 24px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  .search-seed-option_activity-popup-content {
    height: 700px;
    width: 100%;
  }
  .search-seed-option_close-icon {
    cursor: pointer;
    position: absolute;
    z-index: 102;
    margin-left: -26px;
    margin-top: -11px;
  }
  .single-activity-json__fullscreen-button {
    background-color: #414b5c;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 120;
  }
  &.single-activity-json__fullscreen--fullScreen {
    top: 20px;
    transform: translate(-50%, 0);
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
  }
}

.search-seed-option_activity-popup-content {
  .dummy-activity {
    display: none;
  }
}
