.popup-manager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 102;
}

.popup-manager-transparent {
  background-color: transparent;
  display: none;
}

@keyframes bounceInUp {
  0% {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
  }
  60% {
    transform: translateX(-50%) translateY(-10%);
    opacity: 1;
  }
  80% {
    transform: translateX(-50%) translateY(5%);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

.onboarding-popup__container-with-image {
  animation: bounceInUp 1s ease;
}
.onboarding-popup__container.centered {
  animation: bounceInUpCentered 1s ease;
}

@keyframes bounceInUpCentered {
  0% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
  60% {
    transform: translate(-50%, -60%);
    opacity: 1;
  }
  80% {
    transform: translate(-50%, -45%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.onboarding-popup__container-with-image.centered {
  animation: bounceInUpCentered 1s ease;
}

.reward-popup__information-container-with-image.centered {
  animation: bounceInUpCentered 1s ease;
}

@media screen and (max-width: 520px) {
  .onboarding-popup__container.centered {
    animation: none;
  }
}
