.meta-ranking-item {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 34px;
  margin: 8px 0;
  padding: 0 3px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .meta-ranking-item__icon {
    width: 24px;
    display: flex;
    align-items: center;
    padding-left: 0px;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .meta-ranking-item__position {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
    width: 30px;
    display: flex;
    align-items: center;
    padding-left: 0px;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .meta-ranking-item__name {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    flex-grow: 1;
    width: 142px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meta-ranking-item__experience {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.7px 6.6px 1.7px 0px;
    margin-left: 18px;
    min-width: 81px;

    border-radius: 4px;
    background: rgba(0, 0, 0, 0.25);
    position: relative;
  }

  .meta-ranking-item__experience-xs {
    font-size: 10px;
  }
}

.meta-ranking-item--selected {
  background: #5A34E1;
  border-radius: 8px;
  box-shadow: 0px 3.293px 3.293px 0px rgba(0, 0, 0, 0.25);
}

.meta-ranking-item__avatars-button {
  width: auto;
  height: auto;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  left: -8px;

  .avatar-icon {
    position: relative;
    width: 21px;
    height: 21px;
  
    .avatar-icon__bg {
      position: absolute;
      top: -1px;
      left: -13px;

      svg {
        width: 31px;
        height: 21px;
      }
    }
  
    .avatar-icon__image {
      position: absolute;
      bottom: 0px;
      left: 3px;
      transform: translateX(-50%);
      height: 28px;
      width: 28px;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      background-repeat: no-repeat;
      background-size: 125%;
      background-position: top;
    }
  
    .avatar-icon__image--regular {
      background-position: top;
    }
  
    .avatar-icon__image--small {
      background-size: 99%;
      background-position: top;
      height: 31px;
      width: 34px;
    }
  
    .avatar-icon__image--large {
      background-size: 103%;
      background-position: -2px 1px;
      height: 32px;
      width: 35px;
    }
  }
}
