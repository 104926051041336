.feedback-report {
  width: 514px;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;

  .feedback-report__container {
    width: 85%;
  }

  .feedback-report__title {
    color: var(--Grays-gray-01, #303949);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
  }

  .feedback-report__description {
    color: var(--Grays-gray-02, #4d586c);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    white-space: pre-line;
    margin-top: 16px;
  }

  .feedback-report__rating {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    justify-content: center;
    margin-top: 50px;

    &:hover {
      .feedback-report__star-icon path {
        fill: #d3d3d3;
      }
    }

    .feedback-report__star:hover
      ~ .feedback-report__star
      .feedback-report__star-icon
      path,
    .feedback-report__star:hover .feedback-report__star-icon path {
      fill: #ffd700;
    }
  }

  .feedback-report__star {
    font-size: 24px;
    cursor: pointer;
  }

  .feedback-report__star-icon {
    width: 40px;
    height: 40px;

    path {
      fill: #d3d3d3;
      transition: fill 0.2s ease;
    }

    &:hover path,
    &.active path {
      fill: #ffd700;
    }
  }

  .feedback-report__comment-description {
    color: var(--Grays-gray-02, #4d586c);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    white-space: pre-line;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__comment-input {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    resize: vertical;

    color: var(--Grays-gray-02, #4d586c);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    white-space: pre-wrap;

    &::placeholder {
      color: var(--Neutral-Grays-Gray-06, #afb7c6);
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
      outline: none;
      pointer-events: none;
      caret-color: transparent;
    }
  }

  &__submit-button {
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--Grays-gray-09, #f0f4f8);
    color: var(--Grays-white, #fff);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;

    &--active {
      background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
    }
  }

  &__close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 32px;
    height: 32px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ff3d30;
    padding: 8px;

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }
}

.feedback-report__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media screen and (max-width: 520px) {
  .feedback-report {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;

    .feedback-report .feedback-report__container {
      flex: 0 1 auto;
      width: 100%;
      overflow-y: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .feedback-report__close-button {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 12px 22px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      top: 20px;
      right: 14px;
      border-radius: 36px;
      background: var(--Blueberry-Primary-Lighter-blue, #eff1fa);

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }

  .feedback-report__backdrop {
    display: none;
  }
}
