.-meta-tab-missions {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 27px 30px 8px 30px;
  position: relative;

  .-meta-tab-missions__header {
    margin-left: 9px;
  }

  .-meta-tab-missions__header-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 24px 24px 0 0;

    .-meta-tab-missions__header-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }

    .-meta-tab-missions__header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;

      // .shop-level {
      //   margin-right: 9px;
      // }

      .-meta-tab-missions__header-tooltip {
        position: absolute;
        top: 44px;
        left: -40px;
        width: 250px;
        padding: 8px 12px;
        background: #232f40;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        z-index: 99;

        &.fade-in {
          animation: fadeInAnimation ease 0.6s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        &.fade-out {
          animation: fadeOutAnimation ease 0.6s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }

      @keyframes fadeInAnimation {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes fadeOutAnimation {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }

  .-meta-tab-missions__header-bottom {
    margin-bottom: 20px;
  }

  .-meta-tab-missions__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: #ffffff;
  }

  .-meta-tab-missions__subtitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.75);
  }

  .-meta-tab-missions__content {
    @include custom-game-tabs-scrollbar;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 8px 16px 8px;
  }
  .-meta-tab-missions__grid {
    @include custom-game-tabs-scrollbar;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-content: start;
    margin-top: 18px;

    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .-meta-tab-missions__grid-item {
    flex: 1;
    justify-content: center;
    text-align: center;
  }
}

@media (max-height: 750px) {
  .meta-options-menu-drawer__tab-content {
    height: calc(100% - 134px);
  }
}

.-meta-tab-missions__content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  flex-wrap: wrap;
}
