.unit-image {
  position: relative;
  width: 100%;
  height: 100%;

  .unit-image_star {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .unit-image_building {
    position: absolute;
    width: 70%;
    height: 70%;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .unit-image_light {
    position: absolute;
    width: 85%;
    height: 85%;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -36%);
    mix-blend-mode: screen;
  }
}
