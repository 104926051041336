.activity-blueberry-component {
  .feedback-panel-quiz {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
    margin-top: 62px;

    // animación desde abajo con rebote
    /*
  @keyframes feedback-panel__box-rebound {
    0% {
      transform: translate(-50%, 300vh);
    }
    60% {
      transform: translate(-50%, -10%);
    }
    80% {
      transform: translate(-50%, 5%);
    }
    100% {
      transform: translate(-50%, 0%);
    }
  }
  */
    @keyframes fadeInUpBig {
      from {
        transform: translate(-50%, 300vh);
        opacity: 0;
      }

      to {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }

    .feedback-panel__box__quiz {
      // animation: feedback-panel__box-rebound 0.9s ease-out; // animación desde abajo con rebote:
      // animation: fadeInUpBig 0.5s ease forwards;

      position: relative;
      width: calc(100% - 32px);
      left: 50%;
      transform: translateX(-50%);

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 24px;
      border-radius: 12px;
      gap: 12px;
      font-family: Poppins, sans-serif;
      align-items: center;
      background-color: #ffffff;
      z-index: 12;
      margin-top: 24px;

      &.showMoreEnabled {
        height: 50%;

        .feedback-panel__content {
          overflow-y: hidden;
        }

        &.showMoreActive {
          max-height: calc(100% - 80px);
          height: fit-content;
          bottom: 15px;

          .feedback-panel__content {
            overflow-y: auto;
          }
        }
      }

      .feedback-panel__blueberry {
        position: absolute;
        svg, img {
          height: 100px;
        }
      }

      .feedback-panel__title-box {
        display: flex;
        align-items: center;
        gap: 20px;

        .feedback-panel__title-text {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .feedback-panel__subtitle-box {
        display: flex;
        align-items: center;
        gap: 20px;

        .feedback-panel__subtitle-text {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #303949;
        }
      }

      .feedback-panel__content {
        flex: 1;
        width: 100%;
        display: block;
        gap: 10px;
        padding-top: 24px;
        text-align: center;

        .lemonade-feedback-message {
          color: #303949;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &.type-0 {
        border: 3px solid #00e19a;

        .feedback-panel__blueberry {
          top: 0;
          left: 50%;
          transform: translate(-50%, -58%);
        }

        .feedback-panel__title-box {
          padding-top: 26px;
          color: #28c59d;
        }
      }

      &.type-1 {
        border: 3px solid #ff3d30;

        .feedback-panel__blueberry {
          top: 0;
          left: 50%;
          transform: translate(-50%, -60%);

          svg, img {
            height: 100px;
          }
        }

        .feedback-panel__title-box {
          padding-top: 26px;
          color: #ff3d30;
        }
      }

      &.type-2 {
        border: 3px solid #5a34e1;

        .feedback-panel__blueberry {
          top: -73px;
          left: 2px;

          svg, img {
            width: 78px;
          }
        }

        .feedback-panel__title-text {
          color: #5a34e1;
        }
      }

      &.type-3 {
        border: 3px solid #5a34e1;

        .feedback-panel__blueberry {
          top: 0;
          left: 50%;
          transform: translate(-50%, -58%);
        }

        .feedback-panel__title-box {
          padding-top: 26px;
          color: #5a34e1;
        }
      }

      .practice-button {
        margin-top: 12px;
      }
    }

    .feedback-panel__title-audio,
    .feedback-panel__content-audio {
      display: flex;
      padding: 8px;
      align-items: center;
      place-content: center;
      background-color: #eff1fa;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      z-index: 6;

      .speak-button svg {
        height: 18px;
        width: auto;

        path {
          fill: rgba(90, 52, 225, 1);
        }
      }
    }

    @media screen and (min-width: 768px) {
      .feedback-panel__box__quiz {
        width: 100%;
      }
    }

    @media screen and (min-width: 1024px) {
      .feedback-panel__box_quiz {
        width: 100%;
      }
    }
  }
}
