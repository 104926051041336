.practice-view {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .practice-view__exercise {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.practice-view_desktop-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 18% 3% 18%;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer,
    .lemo-scaffolding-mobile .lemo-question-container,
    .lemo-scaffolding-mobile .lemo-controls-container {
      margin: 0 18% !important;
    }

    .lemo-scaffolding-mobile .lemo-navigation-container {
      margin: 0 18% !important;
      width: 64%; // 100% - 18% - 18% (los márgenes)
    }
  }

  &.practice-view_tablet-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 8% 3% 8%;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer,
    .lemo-scaffolding-mobile .lemo-question-container,
    .lemo-scaffolding-mobile .lemo-controls-container {
      margin: 0 8% !important;
    }

    .lemo-scaffolding-mobile .lemo-navigation-container {
      margin: 0 8% !important;
      width: 84%; // 100% - 8% - 8% (los márgenes)
    }
  }

  &.practice-view_phone-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 16px 20px 16px;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer,
    .lemo-scaffolding-mobile .lemo-question-container,
    .lemo-scaffolding-mobile .lemo-controls-container {
      margin: 0 16px !important;
    }

    .lemo-scaffolding-mobile .lemo-navigation-container {
      margin: 0 16px !important;
      width: calc(100% - 32px);
    }
  }
}

/*
.lemo-tooltip {
  visibility: hidden;

  .lemo-tooltip__arrow,
  .lemo-tooltip__wrapper > div > div.lemo-tooltip-content__close > button {
    display: none;
  }
}
*/
