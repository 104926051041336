.building-progress-blocked-card__container {
  width: 249px;
  height: 340px;
  flex-shrink: 0;
  border-radius: 0px 13px 13px 13px;
  background: var(
    --Gradients-Linear-progress,
    linear-gradient(180deg, #7c3de5 22.56%, #3c3cbf 100%)
  );
  box-shadow: 0px 3.41px 3.41px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  border: 2px solid transparent;
  box-sizing: border-box;
  margin: 40px 12px;
  margin-top: 150px;
}

.building-progress-blocked-card__purple-rectangle-background {
  border-radius: 0px 13px 13px 13px;
  position: absolute;
}

.building-progress-blocked-card__white-circle-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: -16px;
  left: -9px;
  z-index: 0;
  color: #6e3aff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.building-progress-blocked-card__title {
  color: #ad8bee;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 26px;
}

.building-progress-card__grey-circle {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 241px;
  background: rgba(255, 255, 255, 0.4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.building-progress-card__lock-image {
}
