$purple: #9940c3;

$violet-dark-2: #3a1587;
$violet-dark-1: #3703a4;
$violet: #490dc8;
$violet-light-1: #5a34e1;

$white-1: #ffffff;
$gray-01: #303949;
$gray-02: #4d586c;
$shadow-white-1: rgba(255, 255, 255, 0.25);
$shadow-black-1: rgba(0, 0, 0, 0.25);

// Anteriores, los dejo pero no se usan
$corporative: #3c3cbf;
$primary-dark: #3703a4;

$primary-light-1: #acacf7;
$primary-light-2: #eff1fa;
$alpha: rgba(172, 172, 247, 0.149);
$green-bb: #00e19a;
$green-light: #d3ffef;
$green-dark: #2ba05c;
$lemonade: #28c59d;
$red-bb: #ff3d30;
$red-light-01: #ff9790;
$red-light-02: #ffccc9;
$red-dark: #ca1a0e;
$black-bb: #000000;
$gray-03: #647082;
$gray-04: #7a8598;
$gray-05: #939db0;
$gray-06: #afb7c6;
$gray-07: #c8cfda;
$gray-08: #dfe5ec;
$gray-09: #f0f4f8;
$gray-10: #fbfcfd;
$disabled-back: $gray-09;
$disabled-front: $gray-06;

$green-light-hover: #00ffe1;
$remarkable-purple: #6e3aff;
$dark-purple: #34247c;
$light-grey: #a2a3b7;
$purple: #9940c3;

//BLUEBERRY COLORS
$blueberry: #3c3cbf;
$primary-dark-blue: #3703a4;
$primary-medium-blue: #5a34e1;
$primary-light-blue-1: #acacf7;
$primary-light-blue-2: #eff1fa;
$alpha: rgba(172, 172, 247, 0.15);

//GREY COLORS
$grey-01: #303949;
$grey-02: #4d586c;
$grey-03: #647082;
$grey-04: #7a8598;
$grey-05: #939db0;
$grey-06: #afb7c6;
$grey-07: #c8cfda;
$grey-08: #dfe5ec;
$grey-09: #f0f4f8;
$grey-10: #fbfcfd;
$white: #ffffff;

//GRADIENTS COLORS
$gradient: linear-gradient(
  133.35deg,
  #3c3cbf 12.5%,
  #8136eb 40.07%,
  #8f35f3 51.43%,
  #c234fa 81.32%
);

$gradient-2: linear-gradient(
  134.73deg,
  #3c3cbf 12.58%,
  #6363fc 47%,
  #7e56fb 69.27%,
  #c234fa 90.33%
);

$linear-purple: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
$linear-blue: linear-gradient(135deg, #72c4ff 14.75%, #4325fa 100%);