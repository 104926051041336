@import '../../../assets/styles/common';

.meta-popup-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  position: relative;
  background: linear-gradient(
    180deg,
    $violet-dark-2 74.89%,
    $violet 99.99%,
    $violet-dark-1 100%
  );
  box-shadow: 0px 4px 60px 0px $shadow-black-1 inset,
    0px 4px 50px 0px $shadow-black-1;
  border-radius: 24px;
  background-position: top;
  background-size: 38%;

  .meta-popup-selector__header-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 24px 24px 0 0;

    .meta-popup-selector__header-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;

      .meta-popup-selector__back-button {
        border-radius: 22px;
        border: 1px solid $white;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
        width: 35px;
        height: 35px;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        margin-left: 19px;

        .meta-popup-selector__back-button__icon {
          display: flex;
          width: 15px;
          height: 15px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          color: $white;
        }
      }
      .meta-popup-selector__header-left__content {
        width: 139px;
        height: 74px;
        border-radius: 24px 37px 37px 0;
        background-color: #9940c3;
        box-shadow: 0px 4px 47px 0px rgba(255, 255, 255, 0.25) inset,
          0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        .meta-popup-selector__header-left__content-level {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .meta-popup-selector__header-left__content-level-text {
            color: #ffffff;
            background-color: #3703a4;
            padding: 6px 12px;
            border-radius: 18px 0 0 18px;
            margin-left: -20px;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            font-family: Poppins, sans-serif;
            line-height: 19px;
          }
          .map-level-counter {
            position: absolute;
            right: -27px;
            .map-level-counter-text {
              top: -6px;
            }
          }
        }

        .meta-popup-selector__header-left__content-coins {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .meta-popup-selector__header-left__content-coins-text {
            color: #ffffff;
            background-color: #3703a4;
            padding: 6px 18px;
            border-radius: 18px 0 0 18px;
            margin-left: 40px;
            font-family: Poppins, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
          }

          .meta-popup-selector__header-left__content-coins-text__misions {
            color: #ffffff;
            background-color: #3703a4;
            padding: 6px 18px;
            border-radius: 18px 0 0 18px;
            font-family: Poppins, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            margin-left: -40px;
          }

          .meta-popup-selector__header-left__content-coins-icon {
            position: absolute;
            right: -27px;
            top: -4px;

            svg {
              height: 42px;
              width: 42px;
            }
          }
        }
      }

      .meta-popup-selector__header-left__content-big {
        width: 239px;
      }
    }

    .meta-popup-selector__header-center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      text-align: center;
    }

    .meta-popup-selector__header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;

      .meta-popup-selector__header-right__content {
        width: 139px;
        height: 74px;
        border-radius: 37px 24px 0px 37px;
        background-color: #9940c3;
        box-shadow: 0px 4px 47px 0px rgba(255, 255, 255, 0.25) inset,
          0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .shop-level {
      margin-right: 9px;
    }

    .meta-popup-selector__header-tooltip {
      position: absolute;
      top: 44px;
      left: -40px;
      width: 250px;
      padding: 8px 12px;
      background: #232f40;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: #fff;
      line-height: 18px;
      z-index: 99;

      &.fade-in {
        animation: fadeInAnimation ease 0.6s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }

      &.fade-out {
        animation: fadeOutAnimation ease 0.6s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fadeOutAnimation {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .meta-popup-selector__header-bottom {
    margin-bottom: 20px;
  }

  .meta-popup-selector__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: #ffffff;
  }

  .meta-popup-selector__subtitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.75);
  }

  .meta-popup-selector__content {
    @include custom-game-tabs-scrollbar;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 8px 16px 8px;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.37) transparent;

    background-image: url('../../../assets/img/ellipse-small.svg');
    background-position: bottom;
    background-size: 28%;
    background-repeat: no-repeat;

    &::-webkit-scrollbar {
      height: 94px; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: green; /* track del scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: red; /* botón del scrollbar */
      border-radius: 20px; /* Si quieres bordes redondeados */
      border: 3px solid green; /* Si quieres una barra de scroll con borde */
    }

    position: relative;
  }

  .meta-popup-selector__grid {
    @include custom-game-tabs-scrollbar;
    width: 100%;
    align-content: center;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 1020px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 760px) {
      grid-template-columns: 1fr;
    }
  }

  .meta-popup-selector__grid-item {
    display: flex;
    justify-content: center;
    height: 210px;
  }

  .meta-popup-selector__toast {
    position: absolute;
    bottom: 15px;
    left: 20px;
    z-index: 999;
  }
  .blueberry-popup_decoration {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .meta-popup-selector {
    width: 100vw;
    height: 100vh;
    position: fixed;
    border-radius: 0;
    max-width: none;
    max-height: none;
    overflow: auto;
    z-index: 1000;
    transform: none;
    inset: 0;

    .meta-popup-selector__grid {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100vw;
      margin-left: -10px;
    }

    .meta-popup-selector__title {
      color: $white;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .meta-popup-selector__content {
      margin: 0;
      padding: 0 8px;
      width: 100vw;
      box-sizing: border-box;
      background-image: none;
    }

    .meta-popup-selector__header {
      z-index: 100;
    }
    .meta-popup-selector__header-top {
      border-bottom: 2.476px solid
        var(--Blueberry-Blue-Blue-01-primary, #5a34e1);
      height: 68px;
      border-radius: 0;
    }

    .meta-popup-selector__header-left__content-big {
      border-radius: 25px;
      background: #9940c3;
      box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 0.25) inset,
        0px 2.728px 2.728px 0px rgba(0, 0, 0, 0.25);
      display: inline-flex;
      padding: 3px 12px 3px 5px;
      align-items: center;
      gap: 23px;
      height: 40px;
      width: 193px;
      margin-top: 1px;
      margin-left: -30px;
    }

    .meta-popup-selector__header-right__content {
      display: none;
    }
    .meta-popup-selector__header-right__close-button__image {
      display: flex;
      width: 35px;
      height: 35px;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;

      position: absolute;
      top: -4px;
      right: -4px;
      cursor: pointer;
      background-color: #9940c3;
      border-radius: 50%;
      z-index: 1001;
    }

    .blueberry-popup_decoration {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 60%;
      height: 100%;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0OTEgNDUzIj48ZWxsaXBzZSBjeD0iMjQ1LjQ2NyIgY3k9IjIwNy42MTQiIGZpbGw9InVybCgjYSkiIGZpbGwtb3BhY2l0eT0iLjUiIHJ4PSIyNDQuOTY0IiByeT0iMjQ0LjQ4MSIvPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0iYSIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDAgMjQ0LjQ4MSAtMjQ0Ljk2NCAwIDI0NS40NjcgMjA3LjYxNCkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjRkM1QUZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjQzY2QUZGIiBzdG9wLW9wYWNpdHk9IjAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48L3N2Zz4=');
      background-size: 100% 40%;
      background-repeat: repeat-y;
      z-index: 0;
      pointer-events: none;
    }

    &.intro {
      background: none;
      box-shadow: none;

      .meta-popup-selector__header--no-background {
        .meta-popup-selector__header-top {
          background: none;
          border-bottom: none;
        }
      }
    }
  }

  .meta-popup-selector
    .meta-popup-selector__header-top
    .meta-popup-selector__header-left
    .meta-popup-selector__header-left__content {
    display: none;
  }

  .meta-popup-selector__decoration {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0OTEgNDUzIj48ZWxsaXBzZSBjeD0iMjQ1LjQ2NyIgY3k9IjIwNy42MTQiIGZpbGw9InVybCgjYSkiIGZpbGwtb3BhY2l0eT0iLjUiIHJ4PSIyNDQuOTY0IiByeT0iMjQ0LjQ4MSIvPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0iYSIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDAgMjQ0LjQ4MSAtMjQ0Ljk2NCAwIDI0NS40NjcgMjA3LjYxNCkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjRkM1QUZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjQzY2QUZGIiBzdG9wLW9wYWNpdHk9IjAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48L3N2Zz4=');
    background-size: 100% 40%;
    background-repeat: repeat-y;
    z-index: 0;
  }

  .meta-popup-selector__header-left__content-level {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 31px;
    border-radius: 12px;
    background: var(--Blueberry-Blue-Blue-02, #3703a4);
  }
  .meta-popup-selector__header-left__content-level-text {
    color: var(--Grays-white, #fff);
    text-align: right;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    width: 35px;
    height: 21px;
    padding: 0px 6px 0px 6px;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
  }
  .meta-popup-selector__content {
    .meta-popup-selector__header-left__content-coins-text {
      color: var(--Grays-white, #fff);
      text-align: right;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .meta-popup-selector__header-left__content-coins {
      display: flex;
      width: 46px;
      height: 22px;
      padding: 0px 6px 0px 6px;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      border-radius: 12px;
      background: var(--Blueberry-Blue-Blue-02, #3703a4);
      margin-left: -4px;
    }
    .meta-popup-selector__header-left__content-coins-icon {
      position: relative;
    }
    .meta-popup-selector__header-left__content-coins-icon svg {
      width: 28px;
      height: 28px;
      position: absolute;
      top: -13px;
      left: 1px;
    }
  }
}

@media screen and (max-width: 520px) {
  .meta-popup-selector.pets {
    .meta-popup-selector__header-left__content {
      position: fixed;
      top: 68px;
      left: 8px;
      z-index: 10;
    }
    .meta-popup-selector__grid {
      margin-top: 58px;
    }
    .meta-popup-selector__header-left__content-level {
      width: 60px;
      height: 21px;
      .map-level-counter {
        margin-left: 10px;
      }
      .map-level-counter .map-level-counter-text {
        top: -10px;
        left: -8px;
      }
    }
  }
}
